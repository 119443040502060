import React from 'react';

function PlotSelection({ plots, selectedPlot, handlePlotSelection, isLoading, error }) {
    return (
        <div className="layout-pane">
            <h3>Analysis Window</h3>
            <div className="plot-selection">
                <select id="plot-select" onChange={handlePlotSelection}>
                    <option value="layout">Layout</option>
                    <option value="spotDiagram">Spot Diagram</option>
                    <option value="rayFan">RayFan Diagram</option>
                    <option value="wavefrontAberration">Wavefront Aberration</option>
                    <option value="mtf">MTF</option>
                </select>
            </div>

            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    {selectedPlot === 'layout' && plots.layout && (
                        <div className="plot-card">
                            <h4>Layout</h4>
                            <img src={plots.layout} alt="Layout" />
                        </div>
                    )}
                    {selectedPlot === 'rayFan' && plots.rayFan && (
                        <div className="plot-card">
                            <h4>RayFan Diagram</h4>
                            <img src={plots.rayFan} alt="RayFan Diagram" />
                        </div>
                    )}
                    {selectedPlot === 'wavefrontAberration' && plots.wavefrontAberration && (
                        <div className="plot-card">
                            <h4>Wavefront Aberration</h4>
                            <img src={plots.wavefrontAberration} alt="Wavefront Aberration" />
                        </div>
                    )}
                    {selectedPlot === 'spotDiagram' && plots.spotDiagram && (
                        <div className="plot-card">
                            <h4>Spot Diagram</h4>
                            <img src={plots.spotDiagram} alt="Spot Diagram" />
                        </div>
                    )}
                    {selectedPlot === 'mtf' && plots.mtf && (
                        <div className="plot-card">
                            <h4>MTF</h4>
                            <img src={plots.mtf} alt="MTF" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default PlotSelection;
