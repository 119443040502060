import React, { useState, useEffect } from 'react';
import './SystemSettings.css'; // Ensure CSS styling is applied

const SystemSettings = ({ systemSettings, handleSystemSettingChange }) => {
    const [wavelengths, setWavelengths] = useState([{ wavelength: '', weight: '' }]);

    useEffect(() => {
        // Ensure initial values are set correctly from props if available
        if (systemSettings.wavelengths) {
            setWavelengths(systemSettings.wavelengths);
        }
    }, [systemSettings]);

    const addWavelength = () => {
        setWavelengths([...wavelengths, { wavelength: '', weight: '' }]);
    };
        // Function to delete the last wavelength
    const deleteWavelength = () => {
        if (wavelengths.length > 1) {
            const newWavelengths = wavelengths.slice(0, -1); // Remove the last wavelength
            setWavelengths(newWavelengths);
            handleSystemSettingChange('wavelengths', newWavelengths);
        }
    };
    
    const handleWavelengthChange = (index, key, value) => {
        const parsedValue = parseFloat(value);
        const newWavelengths = [...wavelengths];
        newWavelengths[index][key] = parsedValue;
        setWavelengths(newWavelengths);
        handleSystemSettingChange('wavelengths',newWavelengths);// Update systemSettings
    };
    const [maxField, setMaxField] = useState({ value: '', isRelative: false });
    const [additionalFields, setAdditionalFields] = useState(['']);


    useEffect(() => {
        // Ensure initial values are set correctly from props if available
        if (systemSettings.maxField) {
            setMaxField(systemSettings.maxField);
        }
        if (systemSettings.additionalFields) {
            setAdditionalFields(systemSettings.additionalFields);
        }
    }, [systemSettings]);
    

    const addField = () => {
        setAdditionalFields([...additionalFields, '']);
        handleSystemSettingChange('additionalFields', [...additionalFields, '']);
    };

    
    const removeField = (index) => {
        const newFields = additionalFields.slice(0, -1); // Remove the last field
        setAdditionalFields(newFields);
        handleSystemSettingChange('additionalFields', newFields);
    };
   
    const handleFieldChange = (index, value) => {
        const constrainedValue = maxField.isRelative 
            ? Math.max(-1, Math.min(1, parseFloat(value)))  // Constrain additional fields if relative
            : parseFloat(value);
    
        const newFields = [...additionalFields];
        newFields[index] = constrainedValue;
        setAdditionalFields(newFields);
        handleSystemSettingChange('additionalFields', newFields);
    };

    const handleMaxFieldChange = (key, value) => {
        const updatedMaxField = {
            ...maxField,
            [key]: parseFloat(value)  // No constraint applied to maxField value
        };
        setMaxField(updatedMaxField);
        handleSystemSettingChange('maxField', updatedMaxField);
    };

    const handleIsFieldRelativeChange = (checked) => {
        const updatedMaxField = {
            ...maxField,
            isRelative: checked,
        };
        setMaxField(updatedMaxField);
        handleSystemSettingChange('maxField', updatedMaxField);
    
        // Optionally, you can also reset the additional fields when relative changes
        const newFields = additionalFields.map(field => 
            checked ? Math.max(-1, Math.min(1, parseFloat(field || 0))) : parseFloat(field)
        );
        setAdditionalFields(newFields);
        handleSystemSettingChange('additionalFields', newFields);
    };

    

    // useEffect(() => {
    //     // Ensure the wavelengths state is in sync with systemSettings prop
    //     handleSystemSettingChange('wavelengths', wavelengths);
    // }, [wavelengths]);


    return (
        <div className="system-settings">
            <h3>System Settings</h3>

            {/* PupilSpec - Aperture Settings */}
            <div className="settings-section">
                <h4>Aperture Settings</h4>
                <select 
                    value={systemSettings.apertureSpace} 
                    onChange={(e) => handleSystemSettingChange('apertureSpace', e.target.value)}
                >
                    <option value="object">Object Space</option>
                    <option value="image">Image Space</option>
                </select>
                <select 
                    value={systemSettings.apertureType} 
                    onChange={(e) => handleSystemSettingChange('apertureType', e.target.value)}
                >
                    <option value="epd">Entrance Pupil Diameter</option>
                    <option value="f/#">F/#</option>
                    <option value="NA">NA</option>
                </select>
                <input 
                    type="number" 
                    placeholder="Aperture Value"
                    value={systemSettings.apertureValue}
                    onChange={(e) => handleSystemSettingChange('apertureValue', e.target.value)}
                />
            </div>

             {/* MaxField - Field Settings */}
             <div className="settings-section">
                <h4>Max Field Settings</h4>
                <select 
                    value={systemSettings.fieldSpace || 'object'} 
                    onChange={(e) => handleSystemSettingChange('fieldSpace', e.target.value)}
                >
                    <option value="object">Object Space</option>
                    <option value="image">Image Space</option>
                </select>
                <select 
                    value={systemSettings.fieldType || 'height'} 
                    onChange={(e) => handleSystemSettingChange('fieldType', e.target.value)}
                >
                    <option value="height">Height</option>
                    <option value="angle">Angle</option>
                </select>
                <input 
                    type="number" 
                    placeholder="Max Field Value"
                    value={maxField.value || ''}
                    onChange={(e) => handleMaxFieldChange('value', e.target.value)}
                />
                <label>
                    <input 
                        type="checkbox"
                        checked={maxField.isRelative}
                        onChange={(e) => handleIsFieldRelativeChange(e.target.checked)}
                    />
                    Is Relative
                </label>
 

                {/* Additional Fields */}
                <div className="settings-section">
                    <h4>Additional Fields</h4>
                    {additionalFields.map((additionalFields, index) => (
                        <div key={index} className="field-group">
                            <input 
                                type="number" 
                                placeholder={`Additional Field`}
                                value={additionalFields || ''}
                                onChange={(e) => handleFieldChange(index, e.target.value)}
                            />
                        </div>
                    ))}
                    <div className="button-group">
                        <button type="button" onClick={addField}>Add</button>
                        {additionalFields.length > 0 && (
                            <button type="button" onClick={removeField}>Remove</button>
                        )}
                    </div>
                </div>
            </div>

            {/* WvlSpec - Wavelength Settings */}
            <div className="settings-section">
                <h4>Wavelength Settings</h4>
                {wavelengths.map((wavelength, index) => (
                    <div key={index} className="wavelength-group">
                        <input 
                            type="number" 
                            placeholder="Wavelength"
                            value={wavelength.wavelength}
                            onChange={(e) => handleWavelengthChange(index, 'wavelength', e.target.value)}
                        />
                        <input 
                            type="number" 
                            placeholder="Weight"
                            value={wavelength.weight}
                            onChange={(e) => handleWavelengthChange(index, 'weight', e.target.value)}
                        />
                    </div>
                ))}

                <div className="button-group">
                    <button type="button" onClick={addWavelength}>Add</button>

                {/* Conditionally render the Delete button if more than one wavelength exists */}
                  {wavelengths.length > 1 && (
                      <button type="button" onClick={deleteWavelength}>Remove</button>
                  )}
                  </div>

            </div>

            {/* Temperature and Pressure */}
            <div className="settings-section">
                <h4>Environmental Settings</h4>
                <label>Temperature (°C)</label>
                <input 
                    type="number" 
                    placeholder="Temperature"
                    value={systemSettings.temperature}
                    onChange={(e) => handleSystemSettingChange('temperature', e.target.value)}
                />
                <label>Pressure (mmHg)</label>
                <input 
                    type="number" 
                    placeholder="Pressure"
                    value={systemSettings.pressure}
                    onChange={(e) => handleSystemSettingChange('pressure', e.target.value)}
                />
            </div>
        </div>
    );
};

export default SystemSettings;
