import React from 'react';

function TopRibbon({ handleFileUpload }) {
    return (
        <div className="top-ribbon">
            <ul>
                <li>File
                    <ul className="dropdown">
                        <li onClick={() => document.getElementById('file-upload-input').click()}>Upload ZMX File</li>
                    </ul>
                </li>
                <li>Tools</li>
                <li>View</li>
            </ul>
            <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={handleFileUpload} />
        </div>
    );
}

export default TopRibbon;
