import React, { useEffect, useState } from 'react';
import './App.css';
import TopRibbon from './TopRibbon';
import SystemSettings from './SystemSettings';
import SurfaceTable from './SurfaceTable';
import PlotSelection from './PlotSelection';
import data from './material/schott_cdgm.csv';
import { csv } from 'd3-fetch';

function App() {

    const [plots, setPlots] = useState({
        layout: null,
        spotDiagram: null,
        rayFan: null,
        wavefrontAberration: null,
        mtf: null,
    });
    const [selectedPlot, setSelectedPlot] = useState('layout');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [surfaces, setSurfaces] = useState([
        { radius: 'Infinity', thickness: '0.0', material: 'Air', aperture: '10', isStop: '' },
        { radius: 'Infinity', thickness: '0.0', material: 'N-SF11', aperture: '10', isStop: 'True' },
        { radius: 'Infinity', thickness: '0.0', material: 'Air', aperture: '10', isStop: '' }
    ]);


    const [materials, setMaterials] = useState([]);

    useEffect(() => {
        const loadCsv = async () => {
            try {
                const csvData = await csv(data);
                const materialList = csvData.map(row => Object.values(row)[0]);
                setMaterials(materialList);
            } catch (error) {
                console.error('Error loading CSV:', error);
            }
        };

        loadCsv();
    }, []);

    const [systemSettings, setSystemSettings] = useState({
        title: 'Test Zemax design',
        units: 'mm',
        temperature: 20.0,  // Environmental settings
        pressure: 760.0,     // Environmental settings

        apertureSpace: 'object',   // Aperture Settings - Space (Object/Image)
        apertureType: 'epd', // Aperture Settings - Type (EPD/F#/NA)
        apertureValue: '1',  // Aperture Settings - Value

        fieldSpace: 'object',   // Field Settings - Space (Object/Image)
        fieldType: 'height',    // Field Settings - Type (Height/Angle)
        maxField: {value:'0', isRelative: false},         // Field Settings - Value
        additionalFields:[''],
        wavelengths: [{ wavelength: '450', weight: '1' }]  // Wavelength settings as an array of objects
    });


    const handleSystemSettingChange = (key, value) => {
        setSystemSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSurfaceChange = (index, key, value) => {
        if (key === 'isStop' && value === true) {
            // Deselect the 'isStop' checkbox for all other surfaces
            const updatedSurfaces = surfaces.map((surface, i) => {
                return i === index
                    ? { ...surface, [key]: value }
                    : { ...surface, isStop: false };
            });
            setSurfaces(updatedSurfaces);
        } else {
            const newSurfaces = [...surfaces];
            newSurfaces[index][key] = value;
            setSurfaces(newSurfaces);
        }
    };

    const addSurfaceRow = () => {
        const newSurface = { radius: 'Infinity', thickness: '0.0', material: 'Air', aperture: '0.0' ,isStop:''};
        const newSurfaces = [...surfaces];
        newSurfaces.splice(surfaces.length - 1, 0, newSurface);
        setSurfaces(newSurfaces);
    };

    const removeSurfaceRow = (index) => {
        if (index === 0 || index === surfaces.length - 1) return;  // Prevent removing object/image surfaces
        const newSurfaces = surfaces.filter((_, i) => i !== index);
        setSurfaces(newSurfaces);
      //  setSurfaces(surfaces.filter((_, i) => i !== index));
    };

    const collectData = () => {
        return {
          pupilSpec: {
            space: systemSettings.apertureSpace,
            type: systemSettings.apertureType,
            value: systemSettings.apertureValue,
        },
        fieldSpec: {
            space: systemSettings.fieldSpace,
            type: systemSettings.fieldType,
            maxField: systemSettings.maxField,
          //  isRelative: systemSettings.isFieldRelative,
            additionalFields: systemSettings.additionalFields,
        },
        wvlSpec: systemSettings.wavelengths || [],
        environment: {
            temperature: systemSettings.temperature,
            pressure: systemSettings.pressure,
        },
        surfaces: surfaces
        };
    };

    const handleApiResponse = (data, status) => {
        if (status === 500) {
            setError('Error Updating Optical Model: Verify model parameters provided by the user.');
            return;
        }
        setError(null);
       
        setPlots({
            layout: `data:image/png;base64,${data.layout}`,
            rayFan: `data:image/png;base64,${data.rayfan_diagram}`,
            wavefrontAberration: `data:image/png;base64,${data.wavefront_aberration}`,
            spotDiagram: data.spotdiagram ? `data:image/png;base64,${data.spotdiagram}` : null,
        });

        if(data.model_data != null)
            setSystemSettings({
                title: data.model_data.systemSettings.title,
                units: data.model_data.systemSettings.units,
                temperature: data.model_data.systemSettings.temperature,  // Environmental settings
                pressure: data.model_data.systemSettings.pressure,     // Environmental settings
            
                apertureSpace: data.model_data.systemSettings.apertureSpace,   // Aperture Settings - Space (Object/Image)
                apertureType: data.model_data.systemSettings.apertureType, // Aperture Settings - Type (EPD/F#/NA)
                apertureValue: data.model_data.systemSettings.apertureValue,  // Aperture Settings - Value
            
                fieldSpace: data.model_data.systemSettings.fieldSpace,   // Field Settings - Space (Object/Image)
                fieldType: data.model_data.systemSettings.fieldType,    // Field Settings - Type (Height/Angle)
                maxField: data.model_data.systemSettings.maxField,         // Field Settings - Value
                additionalFields:data.model_data.systemSettings.additionalFields,
                wavelengths: data.model_data.systemSettings.wavelengths // Wavelength settings as an array of objects

            })
            setSurfaces(data.model_data.surfaces);
    };

    const sendDataToBackend = async () => {
        const data = collectData();
        try {
            // for local development, use http://127.0.0.1:80/update_optical_model
            const response = await fetch('/api/update_optical_model', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const result = await response.json();
                handleApiResponse(result, response.status);
            } else {
                handleApiResponse("error" , response.status)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('file', file);

        try {
            // for local development, use http://127.0.0.1:80/upload
            const response = await fetch('/api/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                handleApiResponse(data, response.status);
            } else {
                setError(`Error: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            setError('Error uploading file. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePlotSelection = (event) => {
        setSelectedPlot(event.target.value);
    };

    return (
        <div className="app-container">
            <TopRibbon handleFileUpload={handleFileUpload} />
            <div className="layout">
                <div className="sidebar">
                    <SystemSettings
                        systemSettings={systemSettings}
                        handleSystemSettingChange={handleSystemSettingChange}
                    />
                </div>
                <div className="main-content">
                    <SurfaceTable
                        surfaces={surfaces}
                        handleSurfaceChange={handleSurfaceChange}
                        addSurfaceRow={addSurfaceRow}
                        removeSurfaceRow={removeSurfaceRow}
                        materials={materials}
                    />
                    <div className="update-button-container">
                        <button onClick={sendDataToBackend}>Update Model</button>
                    </div>
                    <PlotSelection
                        plots={plots}
                        selectedPlot={selectedPlot}
                        handlePlotSelection={handlePlotSelection}
                        isLoading={isLoading}
                        error={error}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
