import React from 'react';
import Select from 'react-select';
import './SurfaceTable.css';

function SurfaceTable({ surfaces, handleSurfaceChange, addSurfaceRow, removeSurfaceRow, materials }) {

    const materialOptions = materials.map(material => ({ value: material, label: material }));

    return (
        <div className="surface-table">
            <h3>Lens Data Editor</h3>
            <table>
                <thead>
                    <tr>
                        <th>Radius</th>
                        <th>Thickness</th>
                        <th>Material</th>
                        <th>Clear Aperture</th>
                        <th>Stop</th>
                    </tr>
                </thead>
                <tbody>
                    {surfaces.map((surface, index) => (
                        <tr key={index} className="surface-row">
                            <td>
                                <input type="text" value={surface.radius} onChange={(e) => handleSurfaceChange(index, 'radius', e.target.value)} />
                            </td>
                            <td>
                                <input type="number" value={surface.thickness} onChange={(e) => handleSurfaceChange(index, 'thickness', e.target.value)} />
                            </td>
                            <td>
                                <Select
                                    value={materialOptions.find(option => option.value === surface.material)}
                                    onChange={(selectedOption) => handleSurfaceChange(index, 'material', selectedOption.value)}
                                    options={materialOptions}
                                    isSearchable
                                />
                            </td>
                            <td>
                                <input type="number" value={surface.aperture} onChange={(e) => handleSurfaceChange(index, 'aperture', e.target.value)} />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={surface.isStop || false}
                                    onChange={(e) => handleSurfaceChange(index, 'isStop', e.target.checked)}
                                />
                            </td>
                            <td>
                                {index > 0 && index < surfaces.length - 1 && (
                                    <button onClick={() => removeSurfaceRow(index)}>Remove</button>
                                )}
                            </td>
                            <td>
                                {index > 0 && index < surfaces.length - 1 && (
                                    <button onClick={() => addSurfaceRow(index)}>Add</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={addSurfaceRow}>Add Surface</button>          
        </div>
    );
}

export default SurfaceTable;
